<template>
  <div>
    <b-container
      class="m-0 w-100"
      fluid
    >
      <header-slot>
        <template #actions>
          <b-button
            v-if="$route.meta.tab === 2"
            variant="success"
            @click="openEditMotiveModalOn"
          >
            <feather-icon icon="PlusIcon" /> Create Motive
          </b-button>
        </template>
      </header-slot>
      <!-- <header-slot>
        <template #actions>
          <b-button @click="openModal()" variant="success" class="mr-1">
            <feather-icon icon="PlusIcon" size="15" class="mr-50 text-white" />
            REGISTER
          </b-button>
        </template>
      </header-slot> -->
      <b-nav
        card-header
        pills
        class="m-0"
      >
        <b-nav-item
          :to="{ name: 'crm-settings-permissions' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          PERMISSIONS
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'crm-settings-motives' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          MOTIVES
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'crm-settings-financial-messages' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="routerName == 'crm-settings-financial-messages'"
        >
          SMS MESSAGES
        </b-nav-item>
      </b-nav>
      <template class="border-top-primary border-3 border-table-radius px-0">
        <router-view :key="key" />
      </template>
    </b-container>
    <create-edit-motive-modal
      v-if="createEditMotiveModalOn"
      :mood="mood"
      @reload="key++"
      @close="closeEditMotiveModalOn"
    />
  </div>
</template>

<script>
import CreateEditMotiveModal from '@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue'

export default {
  components: {
    CreateEditMotiveModal,
  },
  data() {
    return {
      createEditMotiveModalOn: false,
      motiveToSend: {},
      mood: 0,
      key: 1,
    }
  },
  created() {

  },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
  },
  methods: {    
    openEditMotiveModalOn() {
      this.createEditMotiveModalOn = true
      this.mood = 1
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false
    },
  },
}
</script>

<style>

</style>
